import { createRoot } from "react-dom/client";
import App from "./App.jsx";
import { BrowserRouter } from "react-router-dom";
import './assets/global.scss';
import { TonConnectUIProvider } from "@tonconnect/ui-react";


createRoot(document.getElementById("root")).render(
  <TonConnectUIProvider
    manifestUrl="https://ghoul-choice-inherently.ngrok-free.app/tonmanifest.json"
    actionsConfiguration={{
      twaReturnUrl: "https://t.me/unetcore_test_bot",
    }}
  >
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </TonConnectUIProvider>
);