import { Box, Container, Stack, Typography } from "@mui/material";
import ButtonBlue from "../Components/Buttons/ButtonBlue";
import LoadingCircle from "../Components/LoadingCircle";
import { useEffect, useState } from "react";

function Welcome() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadingFunc = async () => {
            if (window.Telegram && window.Telegram.WebApp) {
                window.Telegram.WebApp.expand();
                window.Telegram.WebApp.BackButton.hide();
                setLoading(false);
            }
        };

        loadingFunc();
    }, []);

    if (loading) {
        return <LoadingCircle />;
    }

    return (<>
        <Box sx={{
            backgroundImage: 'url(welcome_bg.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'contain', // Сохраняем 'contain' для мобильных
            backgroundPosition: 'center',
            maxWidth: '600px',
            height: '100vh',
            overflow: 'hidden', // Скрыть переполнение
            width: '100%', // Установить ширину на 100% для адаптивности
            '@media (max-width: 600px)': { // Адаптивные стили для мобильных устройств
                height: '100vh', // Автоматическая высота для мобильных
                backgroundSize: 'cover', // Изменить на cover для мобильных
            }
        }}>

            <Container maxWidth='600px' sx={{
                paddingTop: '80px',
                paddingBottom: '40px',
                height: '100%', // Убедитесь, что контейнер занимает всю высоту
            }}>
                <Stack direction='column' justifyContent='space-between' sx={{ height: '100%' }}>
                    <Stack>
                        <Typography textAlign='center'
                            sx={{
                                fontSize: '44px',
                                color: '#fff',
                                fontWeight: '700',
                                WebkitTextStroke: '1px #3687B9',
                                display: 'inline',
                                textShadow: '0px 2px 4px #00000061',
                                lineHeight: '0.8'
                            }}>
                            Congratulations!
                        </Typography>

                        <Typography textAlign='center'
                            sx={{
                                fontSize: '26px',
                                color: '#fff',
                                fontWeight: '800',
                                WebkitTextStroke: '1px #3687B9',
                                textShadow: '0px 2px 4px #00000061'
                            }}>
                            You've received 1000 Cati!
                        </Typography>
                    </Stack>
                    <Stack alignItems='center'>
                        <Typography
                            sx={{
                                fontSize: '20px',
                                color: '#fff',
                                fontWeight: '800',
                                WebkitTextStroke: '1px #3687B9',
                                display: 'inline',
                                textShadow: '0px 2px 4px #00000061',
                                lineHeight: '1'
                            }}>
                            We’re thrilled to reward you with these coins as a token of our appreciation for being a valued user of our app. Your journey with us is important, and we can’t wait to see how you use your Cati to enhance your experience. Keep exploring, and enjoy all the exciting features we have in store for you! Happy spending!
                        </Typography>
                        <ButtonBlue text={'continue'} />
                    </Stack>
                </Stack>
            </Container>
        </Box >
    </>);
}

export default Welcome;