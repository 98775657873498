import { Box, Typography } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import dbUrls from '../../database/dbUrls';

function ButtonBlue({ text }) {
    const MotionBox = motion(Box);
    const navigate = useNavigate();

    const onClick = async () => {
        const telegramID = window.Telegram.WebApp.initDataUnsafe.user.id;

        try {
            const response = await fetch(new dbUrls().users.updateOpenWeb(), {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ user_id: telegramID }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        }

        navigate('/stake')
    };

    return (
        <MotionBox
            onClick={() => onClick()}
            sx={{
                backgroundImage: 'url(/button_blue.png)',
                width: '362px',
                height: '61px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                margin: '20px 0px'
            }}
            whileTap={{ scale: 0.9 }} // Уменьшение при нажатии
            transition={{
                type: 'spring',
                stiffness: 500,
                damping: 15
            }}
        >
            <Typography sx={{
                marginBottom: '8px',
                color: '#fff',
                fontWeight: '800',
                fontSize: '32px',
                WebkitTextStroke: '1px #3687B9',
            }}>
                {text}
            </Typography>
        </MotionBox>
    );
}

export default ButtonBlue;